<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 1,
})
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.8402 27.3241L27.5068 15.6574C27.7193 15.4355 27.8858 15.1738 27.9968 14.8874C28.2302 14.3193 28.2302 13.6822 27.9968 13.1141C27.8858 12.8277 27.7193 12.566 27.5068 12.3441L15.8402 0.677424C15.6226 0.459868 15.3643 0.287293 15.0801 0.169552C14.7958 0.0518115 14.4912 -0.00878966 14.1835 -0.00878967C13.5621 -0.0087897 12.9662 0.238049 12.5268 0.677424C12.0875 1.1168 11.8406 1.71272 11.8406 2.33409C11.8406 2.95546 12.0875 3.55138 12.5268 3.99076L20.2268 11.6674L2.51685 11.6674C1.89801 11.6674 1.30452 11.9133 0.866929 12.3508C0.429346 12.7884 0.183513 13.3819 0.183513 14.0008C0.183513 14.6196 0.429345 15.2131 0.866929 15.6507C1.30452 16.0883 1.89801 16.3341 2.51685 16.3341L20.2268 16.3341L12.5268 24.0108C12.3081 24.2277 12.1346 24.4857 12.0161 24.7701C11.8976 25.0544 11.8366 25.3594 11.8366 25.6674C11.8366 25.9755 11.8976 26.2804 12.0161 26.5648C12.1346 26.8491 12.3081 27.1072 12.5268 27.3241C12.7438 27.5428 13.0018 27.7164 13.2862 27.8348C13.5705 27.9533 13.8755 28.0143 14.1835 28.0143C14.4915 28.0143 14.7965 27.9533 15.0809 27.8348C15.3652 27.7164 15.6233 27.5428 15.8402 27.3241Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>
